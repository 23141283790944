<template>
    <div class="discount-mask"></div>
</template>

<script lang="ts" setup>
import type { Api } from '~~/global'

const Route = useRoute()
const { $lang } = useNuxtApp()
const { buildHeaders, baseUrl, endpoints } = useApiClient()

let url = ''

if (!Route.params.id) {
    throw createError({
        statusCode: 400,
        message: 'Error de solicitud',
    })
}

if (Route.name === 'go-id') {
    try {
        if (Route.params.id.includes('-direct-')) {
            const newurl = (Route.params.id as string).replace('-direct-', '')
            await navigateTo(newurl, {
                redirectCode: 302,
            })
        } else {
            url = await $fetch<string>(
                endpoints.general.shortLink.offer.replace('_ID_', Route.params.id as string),
                {
                    method: 'GET',
                    baseURL: baseUrl,
                    headers: buildHeaders(),
                },
            )

            await navigateTo(url, {
                redirectCode: 302,
                open: {
                    target: '_self',
                },
                external: true,
            })
        }
    } catch (e) {
        console.log('Error inside offers mask: ', { e })

        throw createError({
            statusCode: 500,
            message: $lang.views.discountMask.unexpected_error,
        })
    }
}

if (Route.name === 'open-id') {
    try {
        url = await $fetch<string>(
            endpoints.general.shortLink.coupon.replace('_ID_', String(Route.params.id)),
            {
                method: 'GET',
                baseURL: baseUrl,
                headers: buildHeaders(),
            },
        )

        await navigateTo(url, {
            redirectCode: 302,
            open: {
                target: '_self',
            },
            external: true,
        })
    } catch (e) {
        console.log('Error inside coupons mask: ', { e })

        throw createError({
            statusCode: 500,
            message: $lang.views.discountMask.error_obtaining_coupon,
        })
    }
}
</script>
